import { DataStore } from '$lib/firebase/datastore';
import { postData } from '$lib/ts/postData';

class MetaWeb {
  dataStore: DataStore;

  private constructor(dataStore: DataStore) {
    this.dataStore = dataStore;
  }

  static async initialize() {
    const dataStore = new DataStore();
    return new MetaWeb(dataStore);
  }

  async sendToken(tokenId: number, senderAddress: string, receiverAddress: string) {
    // Guard clauses assemble
    if (!tokenId) throw 'No tokenId';
    if (!senderAddress) throw 'No senderAddress';
    if (!receiverAddress) throw 'No receiverAddress';
    const { message, status, data }: any = await this.dataStore.deleteUserToken(
      tokenId,
      senderAddress
    );
    if (status != 'SUCCESS') throw 'No token found';

    if (receiverAddress === 'NULL') return; // If sending to null, just skip sending

    await this.dataStore.createToken(data, receiverAddress);
  }

  isAnonAccount(username: string) {
    if (!username) return true;
    const anonRegex =
      /anonymousUser-[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]/;
    return anonRegex.test(username);
  }

  async getCurrentUser() {
    try {
      const user = await this.dataStore.getCurrentUser();
      return {
        id: user?.id,
        email: user?.email,
        userName: user?.userName,
        isAnon: this.isAnonAccount(user?.userName),
      };
    } catch (error) {
      return {
        id: null,
        email: null,
        userName: null,
        isAnon: null,
      };
    }
  }

  async createToken(tokenData: any) {
    const dataStore = new DataStore();

    const initialTokenDocument = await dataStore.getTokenDocument(tokenData.placeId);
    if (initialTokenDocument.status === 'SUCCESS') {
      return {
        placeName: initialTokenDocument.placeName,
        tokenDescription: initialTokenDocument.tokenDescription,
        imageData: initialTokenDocument.imageData,
      };
    }

    let imageDataIn: any = '';
    if (tokenData.imageData) {
      imageDataIn = tokenData.imageData;
    } else {
      const prompt = tokenData.prompt || tokenData.placeName || 'undefined';
      if (prompt) {
        const apiResponse = await postData('/api/v1/createimage', {
          prompt: prompt,
        });
        imageDataIn = String(apiResponse.base64);
      }
    }
    if (imageDataIn == 'undefined') imageDataIn = null;

    const { placeName, tokenDescription, imageData } = await dataStore.createTokenDocument({
      placeId: tokenData.placeId,
      placeName: tokenData.placeName,
      tokenDescription: tokenData.tokenDescription || null,
      imageData: imageDataIn,
    });

    return {
      placeName: placeName,
      tokenDescription: tokenDescription,
      imageData: imageData,
    };
  }
}

export { MetaWeb };
